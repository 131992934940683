export default [
  {
    header: 'Customer Opinion',
    roles: {
      admin: ["read", "write"],
      consultant: ["read", "write"],
      supervisor: ["read", "write"],
      client: ["read", "write"],
    }
  },
  {
    title: 'Opinions',
    route: 'habit-customersOpinions-list',
    icon: 'UsersIcon',
    roles: {
      admin: ["read", "write"],
      consultant: ["read", "write"],
      supervisor: ["read", "write"],
      client: ["read", "write"],
    }
  },
  {
    title: 'Satisfaction Dashboard',
    route: 'habit-customersOpinions-dashboard',
    icon: 'BarChart2Icon',
    roles: {
      admin: ["read", "write"],
      consultant: ["read", "write"],
      supervisor: ["read", "write"],
      client: ["read", "write"],
    }
  },
]
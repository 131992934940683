export default [
  {
    header: 'Daily Dialogue',
    roles: {
      admin: ["read", "write"],
      consultant: ["read", "write"],
      supervisor: ["read", "write"],
      client: ["read", "write"],
    }
  },
  {
    title: 'Daily Dialogue',
    icon: 'CoffeeIcon',
    children: [
      {
        title: 'Nuevo',
        route: 'habit-meeting-new',
        roles: {
          admin: ["read", "write"],
          consultant: ["read", "write"],
          supervisor: ["read", "write"],
          client: ["read", "write"],
        }
      },
      {
        title: 'Communications',
        route: 'habit-meeting-communications',
        roles: {
          admin: ["read", "write"],
          consultant: ["read", "write"],
          supervisor: ["read", "write"],
          client: ["read", "write"],
        }
      },
      // {
      //   title: 'Customers Opinions',
      //   route: 'habit-customersOpinions-list',
      //   roles: {
      //     admin: ["read", "write"],
      //     consultant: ["read", "write"],
      //     supervisor: ["read", "write"],
      //     client: ["read", "write"],
      //   }
      // }
    ]
  },
  {
    title: 'DD Dashboards',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Recognitions',
        route: 'habit-recognition-dashboard',
        icon: 'StarIcon',
      },
      {
        title: 'Improvement Opport.',
        route: 'habit-improvement-dashboard',
        icon: 'CheckIcon',
      },
      {
        title: 'Daily Dialogues',
        route: 'habit-happiness-dashboard',
        icon: 'SmileIcon',
      },
      {
        title: 'Mood',
        route: 'habit-happiness-measure-of-happiness',
        icon: 'BarChart2Icon',
      },
    ]
  },
  
]
<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
    <div class="d-flex justify-content-center align-items-center">
      <b-img
        class="d-sm-block d-none"
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name.slice(0, 2) }}.</span>
    </div>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="changeLocale(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import { localize } from 'vee-validate';
import axios from '@axios'
import { getClient } from "@/@core/queries/clients";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  props: {
    clientId: {
      type: String,
      default: false
    }
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale
      localize(locale)
    },
  },
  mounted() {
    // Search for client default language in local storage and set the locale
    if (localStorage.getItem("clientData")) {
      let clientLanguage = JSON.parse(localStorage.getItem("clientData")).default_language
      if (clientLanguage) this.changeLocale(clientLanguage)
    }
    // Update client data in local storage
    if (this.clientId) {
      axios
        .post("/graphql", {
          query: getClient,
          variables: { query: { _id: this.clientId } },
        })
        .then(async(response) => {
          if (response.data.errors) throw new Error(response.data.errors[0].message)
          const clientData = response.data?.data?.client
          let clientLanguage = clientData.default_language
          if (clientLanguage) this.changeLocale(clientLanguage)
          localStorage.setItem('clientData', JSON.stringify(clientData))
        })
        .catch((err) => {
          console.log(err);
        })
    }
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'es',
        img: require('@/assets/images/flags/es.png'),
        name: 'Español',
      },
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
      /*
      {
        locale: 'fr',
        img: require('@/assets/images/flags/fr.png'),
        name: 'French',
      },
      {
        locale: 'de',
        img: require('@/assets/images/flags/de.png'),
        name: 'German',
      },
      {
        locale: 'pt',
        img: require('@/assets/images/flags/pt.png'),
        name: 'Portuguese',
      },
      */
    ]
    /* eslint-disable global-require */

    return {
      locales,
    }
  },
}
</script>

<style>

</style>
